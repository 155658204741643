import React, { useEffect, useState } from 'react';
import useSkillset from '../../Hooks/useSkillset';
import './Skill.css';
import SkillSingle from './SkillSingle/SkillSingle';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
import Header from '../AllExist/Header/Header';
import Footer from '../AllExist/Footer/Footer';
import useSound from 'use-sound';
import HoverBTNSound from '../../assets/img/socialSound.mp3';
import Loader from '../AllExist/Loader/Loader';

const Skill = () => {
  const [MySkills] = useSkillset([]);
  const [GetSkills, setGetSkills] = useState([]);
  const [play] = useSound(HoverBTNSound);



  useEffect(()=>{
    const expertiseSkill = MySkills?.filter(MySkill => MySkill.skill_categories === 'expertise');
    setGetSkills(expertiseSkill);
  },[MySkills]);


  
    const OpenCategories = click =>{
      const FilterMySkills = MySkills?.filter(MySkill => MySkill.skill_categories === click);
      setGetSkills(FilterMySkills)
      // play()
    }

    return (
      <div className='MainSkill'>
      <Header></Header>

        <div className="MainSkill-btn">
            <div className="Skill-link">
                <button onClick={()=>OpenCategories('expertise')} className="work-button">Expertise</button>
            </div>
            <div className="Skill-link">
                <button onClick={()=>OpenCategories('wordpress')} className="work-button">WordPress</button>
                {/* <button onClick={()=>OpenCategories('Front_End_Back_End')} className="work-button">Development</button> */}
            </div>
            <div className="Skill-link">
                <button onClick={()=>OpenCategories('Familiar')} className="work-button">Familiar</button>
            </div>
            <div className="Skill-link">
                <button onClick={()=>OpenCategories('Tools')} className="work-button">Tools</button>
            </div>
            <div className="Skill-link">
                <button onClick={()=>OpenCategories('Others')} className="work-button">Others</button>
            </div>
        </div>
        <div className="MainSkill-page pt-3">
            <div className='Skill'>
              {MySkills?.length?
              <div className="Skill-main">
              <Swiper
              spaceBetween={25}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              loop={true}
              //  pagination={{
              //    clickable: true,
              //  }}
              breakpoints={{
                350: {
                  // width: 576,
                  slidesPerView: 1,

                },
                470: {
                  // width: 768,
                  slidesPerView: 2,
                  spaceBetween: 20

                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20

                },
                1025: {
                  // width: 768,
                  slidesPerView: 4,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              >
                  {
                      GetSkills?.map(GetSkill=> 
                        <SwiperSlide key={GetSkill?.skill_name}>
                          <SkillSingle GetSkill={GetSkill}>
                            
                          </SkillSingle>
                        </SwiperSlide>
                      )
                  }

                  </Swiper>   
              </div>
              :
              <div className="loader-div">
              <Loader></Loader>
              </div>
              }
        </div>
        </div>
      <Footer></Footer>
      </div>
    );
};

export default Skill;