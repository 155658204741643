import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import '../SingleCertificate/SingleCertificate.css';
import { NavLink } from 'react-router-dom';

const SingleCertificate = (props) => {
    const [Hide, setHide] = useState(false);
    // const [showCTImg, setShowCTImg] = useState(true);

    const {Certificate_img, certificate_title} = props?.certificates;
    let CertificateImg = require('../../../assets/img/Certificate/' + Certificate_img);

    
    const PortfolioSingleHide = e =>{
        setHide(true)
    }
    const PortfolioSingleShow = e =>{
        setHide(false)
    }
    let ShowStyle;
    
    if(Hide){
    ShowStyle = {
        display:"block",
        top: "0",
        left: "0",
    }}else{
    ShowStyle = {
        left: "-100%",
        top: "0"
    }}

    const ShowCTImg =() =>{
        // document.querySelector('#full-image-ct-id').style.display='block';
    }


    return (
        <div className='PortfolioSingle mb-4'>
            <div className="PortfolioSingle-div text-light">
                   <div className="PortfolioSingle-img-div">
                        <div style={{backgroundImage: `url(${CertificateImg})`, minHeight: '230px'}} onMouseEnter={PortfolioSingleHide} onMouseLeave={PortfolioSingleShow} className="PortfolioSingle-img">
                            
                            <div style={ShowStyle} className="CertificateSingle-img-hide d-flex justify-content-center align-items-center">
                                <NavLink to={`/certificate?name=${Certificate_img}`} className='work-hove-link'>
                                    <FontAwesomeIcon icon={faUpDownLeftRight} />
                                </NavLink>
                            </div>
                        </div>
                   </div>
                <div className="PortfolioSingle-content pt-3">
                    <h5>{certificate_title}</h5>
                </div>

            </div>
        </div>
    );
};

export default SingleCertificate;