import React from 'react';
import './Footer.css';
import useSound from 'use-sound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import socialSound from '../../../assets/img/socialSound.mp3';



const Footer = () => {

    // var socialAudio = document.getElementById('social-sound'); 
    
    const [play, {stop}] = useSound(socialSound);
    // const SocialAudioAound = e =>{

    // }
    
    return (
        <div className='Footer'>
            <div className="Footer-main">    
                <div className="Footer-social d-flex justify-content-center gap-4">
                         
                    <div className="social-link-div">
                        <a href="https://github.com/Minhajul-galib" onMouseEnter={play} onMouseLeave={stop} onClick={play} className='social-link' target='_blank'>
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    </div>
                    <div className="social-link-div">
                    <a href="https://www.linkedin.com/in/minhajul-islam-galib/" onMouseEnter={play} onMouseLeave={stop} onClick={play} className='social-link' target='_blank'>
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    </div>
                    <div className="social-link-div">
                    <a href="https://wa.me/+8801731305152" onMouseEnter={play} onMouseLeave={stop} onClick={play} className='social-link' target='_blank'>
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default Footer;