import { useState, useEffect } from "react";

const useCertificate = () =>{
    const [allCertificate, setAllCertificate] = useState([]);

    useEffect(()=>{
        fetch('./certificate.json')
        .then(res => res.json())
        .then(data => setAllCertificate(data));
    },[])
    return [allCertificate];
}

export default useCertificate;