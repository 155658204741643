import React, { useEffect, useState } from 'react';
import Header from '../AllExist/Header/Header';
import Footer from '../AllExist/Footer/Footer';
import './Portfolio.css';
import UseWork from '../../Hooks/UseWork';
import PortfolioSingle from './PortfolioSingle/PortfolioSingle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper";
import Loader from '../AllExist/Loader/Loader';
// import ReactGA from 'react-ga';


const Portfolio = () => {
    const [work] = UseWork([]);
    const [MyWorks, setMyWork] = useState([]);

    useEffect(()=>{
        setMyWork(work)
    },[work])
    const newWork = [...MyWorks]

    const WorkCategories = click =>{

        // ReactGA.event({'category':'Test','action':'submit','label':'label'});
        
        if(click == "all"){
            setMyWork(work);

        }else{
            const filterWork = work?.filter(MyWork => MyWork.work_Categories === click);
            setMyWork(filterWork);
        }
    }



    return (
        <div className='Portfolio'>
            <Header></Header>
            <div className="Portfolio-div">
            <div className="Portfolio-btn-div">
                <div className="Portfolio-btn">
                    <button onClick={()=>WorkCategories('all')} className='work-button'>All</button>
                </div>
                <div className="Portfolio-btn">
                    <button onClick={()=>WorkCategories('WordPress')} className='work-button'>WordPress</button>
                </div>
                <div className="Portfolio-btn">
                    <button onClick={()=>WorkCategories('React')} className='work-button'>React</button>
                </div>
                <div className="Portfolio-btn">
                    <button onClick={()=>WorkCategories('staticWeb')} className='work-button'>Static Website</button>
                </div>
                <div className="Portfolio-btn">
                    <button onClick={()=>WorkCategories('achievement')}className='work-button'>Achievement</button>
                </div>
            </div>
            {work?.length?
            <div className="Portfolio-main">
                <Swiper
                    className='pt-4 pt-lg-2'
                    spaceBetween={40}
                    slidesPerView={3}
                    slidesPerGroupSkip={10}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter:true
                    }}
                    initialSlide={3}
                    loop={true}
                    pagination={{
                        clickable: false,
                    }}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    breakpoints={{
                        350: {
                        // width: 576,
                        slidesPerView: 1,
                        },
                        576: {
                        // width: 576,
                        slidesPerView: 1,
                        },
                        576: {
                        // width: 768,
                        slidesPerView: 2,
                        },
                        900: {
                        // width: 768,
                        slidesPerView: 3,
                        },
                    }}
                
                    >
                        {
                            newWork?.map(Work =>
                            <SwiperSlide key={Work?.id}>
                                    <PortfolioSingle Work={Work}>
                                        
                                    </PortfolioSingle>
                            </SwiperSlide>     
                            )
                        }

                    </Swiper>
            </div>
             :       
            <div className="loader-div">
                <Loader></Loader>
            </div>}

            </div>
            <Footer></Footer>
        </div>
    );
};

export default Portfolio;