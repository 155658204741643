import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../AllExist/Footer/Footer';
import Header from '../AllExist/Header/Header';
import HomeBanner from './HomeBanner/HomeBanner';

const Home = () => {


    return (
        <div>
            <Header></Header>
            <HomeBanner />
            <Footer></Footer>
        </div>
    );
};

export default Home;