import React, { useEffect, useState } from 'react';
import Footer from '../AllExist/Footer/Footer';
import Header from '../AllExist/Header/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import useCertificate from '../../Hooks/useCertificate';
import SingleCertificate from './SingleCertificate/SingleCertificate';
import '../Certificate/Certificate.css';
import FullSizeCT from './FullSizeCT/FullSizeCT';
import { logDOM } from '@testing-library/react';

const Certificate = () => {
    
    const [certificate] = useCertificate([]);
    const [getCertificate, setGetCertificate] = useState([]);
    useEffect(()=>{
        setGetCertificate(certificate);
    },[certificate]);
    

    const ShowCTImg =() =>{
        // document.querySelector('#full-image-ct-id').style.display='none';
    }

    return (
        <div className='Certificate'>
            <Header></Header>
                <div className="Certificate-div">
                <Swiper
                        className='pt-5'
                        spaceBetween={37}
                        slidesPerView={3}
                        autoplay={{
                            delay: 10005000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        //  pagination={{
                        //    clickable: true,
                        //  }}
                        breakpoints={{
                            350: {
                            // width: 576,
                            slidesPerView: 1,
                            },
                            576: {
                            // width: 576,
                            slidesPerView: 1,
                            },
                            576: {
                            // width: 768,
                            slidesPerView: 2,
                            },
                            900: {
                            // width: 768,
                            slidesPerView: 3,
                            },
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination]}
                        >
                            {
                                getCertificate?.map(certificates =>

                                <SwiperSlide key={certificates?.id}>
                                        <SingleCertificate certificates={certificates}>

                                        </SingleCertificate>
                                </SwiperSlide>     
                                )
                            }

                            </Swiper>   
                </div>
                <div id='full-image-ct-id' className='full-image-ct-div fullImageCT'>
                    {/* <div className="full-image-ct-hide"  onClick={ShowCTImg}>
                    </div> */}
                    <div className="full-image-ct-id-main">
                        <FullSizeCT></FullSizeCT>
                    </div>
                </div>
            <Footer></Footer>
        </div>
    );
};

export default Certificate;