import React, { useEffect, useState } from 'react';
import './SkillSingle.css';
// import styled, { keyframes } from 'styled-components';


const SkillSingle = (props) => {
    
    const {skill_img, skill_name, skill_text, skill_rate} = props?.GetSkill;

    // const [progress, setProgress] = useState(0);

    const [skillRate, setSkillPro] = useState(0);
    const [skillStyle, setSkillStyle] = useState(390);



    useEffect(() => {
        const interval =  setInterval(() => {
            

        //   if (skill_rate < 100) {
              setSkillPro((rate) =>{ 
                if(rate < skill_rate) return rate+5;

                clearInterval(interval);
                return rate;
              });
            // }
            }, 20);
                  
            }, [skill_rate]);



    useEffect(()=>{
        const interval =  setInterval(() => {
            
            let ratePersentage = 390 * skill_rate;
            const valuRate = Math.round(ratePersentage / 100);
            let actualRate = 390 - valuRate;
            // let stRate = 390
            setSkillStyle((stRate = 390) =>{ 
                    if(stRate > actualRate) return stRate - 15;
    
                    clearInterval(interval);
                    return stRate;
                  });

                }, 1000);
            
                
    },[skillRate])       
            
    const ProgressStyle ={
        // strokeDasharray : skillStyle,
        strokeDashoffset : skillStyle,
    }
                
                     
        return (
            <div className='SkillSingle'>
                <div className="SkillSingle-div contact-box text-light">
                    {/* <div className="SkillSingle-img">
                        <img src={skillImag} alt="" width='35%' />
                    </div> */}
                    <div className="SkillSingle-body pt-4">
                        {/* <p>{skill_text}</p> */}

                        <div className="skillPro">
                        <div className="outer">
                            <div className="inner">
                                <p id='skill_rate'>{skillRate}%</p>
                            </div>      
                        </div>
                    
                        <svg xmlns="http://www.w3.or/2000/svg" version="1.1" width="150px" height="150px">
                            <defs>
                                <linearGradient id="GradientColor">
                                    <stop offset="0%" stopColor='#FFFFFF' />
                                    <stop offset="100%" stopColor='#87CEEB' />
                                </linearGradient>
                            </defs>
                                <circle style={ProgressStyle} cx="75" cy="75" r="65" strokeLinecap='round'></circle>

                        </svg>

                    </div>

                
                    </div>
                    <h4 className='text-center pt-3 pb-3'>{skill_name}</h4>
                    {/* <p className='text-center'>{skill_text}</p> */}
                    {/* <div className="SkillSingle-footer">
                        <NavLink to='/link'>Visit Website</NavLink>
                    </div> */}
                </div>
            </div>
    );
};

export default SkillSingle;

// skill_link, skill_rate, skill_list, skill_list_link