import React, { useEffect, useState } from 'react';

const useBlog = () => {
    const [allBlog, setAllBlog] = useState([]);
    
    useEffect(()=>{
        fetch('../blog.json')
        .then(res=>res.json())
        .then(data=>setAllBlog(data))
    },[])
    return[allBlog];
};

export default useBlog;

