import React, { useEffect, useState } from 'react';
import './SingleBlog.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



const SingleBlog = (props) => {
    const [Hide, setHide] = useState(false);
    const [imageStyle, setImgStyle] = useState("top");

    const {id,title, des, Thumbnail_img} = props?.blog;
    let workImg = require('../../../assets/img/blog/' + Thumbnail_img);
    const description = des?.slice(0,70);
    const topImage = {
        backgroundImage: `url(${workImg})`,
        backgroundPositionY: imageStyle
    }
    const PortfolioSingleHide = e=>{
        setHide(true)
        setImgStyle("bottom")
        
    }
    const PortfolioSingleShow = e=>{
        setHide(false)
        setImgStyle("top")
    }

    let ShowStyle;
    
    if(Hide){
    ShowStyle = {
        display:"block",
        top: "0",
        left: "0",
    }}else{
    ShowStyle = {
        left: "-100%",
        top: "0"
    }}
    

    return (
        <Link style={{textDecoration:'none'}} to={`/blog/${id}`}>
            <div className='PortfolioSingle mb-4'>
            <div className="PortfolioSingle-div text-light">
                   <div className="PortfolioSingle-img-div">
                        <div style={topImage} onMouseEnter={PortfolioSingleHide} onMouseLeave={PortfolioSingleShow} className="PortfolioSingle-img">
                            <div style={ShowStyle} className="PortfolioSingle-img-hide d-flex gap-3 justify-content-center align-items-center">
                             
                            </div>
                        </div>
                   </div>
                <div className="PortfolioSingle-content pt-3">
                    <h5 className='pb-2'>{title}</h5>
                    <p>{description} . . . .</p>
                </div>
            </div>
           </div>
        </Link>
    );
};

export default SingleBlog;