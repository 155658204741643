import { useEffect, useState } from "react";


const useSkillset = () =>{
    const [MySkills, setMySkill] = useState([]);

    useEffect(()=>{
        fetch('./skillset.json')
        .then(res=> res.json())
        .then(data => setMySkill(data));
    }, []);
    return [MySkills];
}

export default useSkillset;