import React, { useEffect, useState } from 'react';
import './HomeViewCode.css';
import myImage from '../../../assets/img/galib.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styled,{ keyframes } from 'styled-components';
import { useInterval } from 'react-use';
const HomeViewCode = () => {
    const WPArray="A WordPress Developer ".split("")
    const MernArray="A MERN-Stack Developer".split("")
    const [items, setItems] =useState(WPArray)
    const[count, SetCount] = useState(0)
    const [play,setPlay] = useState(false)


    useInterval(
        ()=>{

            setItems(WPArray)
            SetCount(count +1)

            if(count ==1){
                SetCount(0)
                setItems(MernArray)
            }
        },
        play?7000:null
    )
    useEffect(()=>{
        const timer = setTimeout(()=>{
            setItems(MernArray)
            setPlay(true)
        },5500)
        return()=>clearTimeout(timer)
    },[]) 

    return (
        <div className='HomeViewCode'>
            <div className="HomeViewCode-div1">
                <div className="image-bg-home-view">
                    <div className="HomeViewCode-div2">
                    <img src={myImage} alt="" />
                    </div>
                </div>
                <h3 className='text-light'>Minhajul Islam Galib</h3>
                <Wrapper className='text-light home-view-dev-line'>
                    {items.map((item,index)=>(
                    <span key={index}>{item}</span> ))}
                  
                </Wrapper>
                <div className="home-bthn mt-4">
                    <a title='+8801731305152' className='work-button' href='https://wa.me/+8801731305152'> 
                        WhatsApp <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                    <a title='migalibctg@gmail.com' className='work-button' href='mailto:migalibctg@gmail.com'> 
                        Email <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HomeViewCode;


const animation = keyframes`
0%{ opacity:0; transform:translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg);filter:blur(10px);}
25%{ opacity:1; transform:translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);filter:blur(0px);}
75%{ opacity:1; transform:translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);filter:blur(0px);}
100%{ opacity:0; transform:translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg);filter:blur(10px);}
`
const Wrapper = styled.span`
diplay:inline-block;
span{
    opacity:0;
animation-name: ${animation};
animation-duration:7s;
animation-fill-mode:forwards;
animation-iteration-count:infinite;
animation-timing-function:cubic-bezier(0.075,0.82,0.165,1);
}
span:nth-child(1){
    animation-delay:0.2s;
}
span:nth-child(2){
    animation-delay:0.4s;
}
span:nth-child(3){
    animation-delay:0.1s;
}
span:nth-child(4){
    animation-delay:0.2s;
}
span:nth-child(5){
    animation-delay:0.10s;
}
span:nth-child(6){
    animation-delay:0.12s;
}
span:nth-child(7){
    animation-delay:0.14s;
}
span:nth-child(8){
    animation-delay:0.16s;
}
span:nth-child(9){
    animation-delay:0.18s;
}
span:nth-child(10){
    animation-delay:0.20s;
}
span:nth-child(12){
    animation-delay:0.22s;
}
span:nth-child(13){
    animation-delay:0.24s;
}
span:nth-child(14){
    animation-delay:0.26s;
}
span:nth-child(15){
    animation-delay:0.28s;
}
span:nth-child(16){
    animation-delay:0.30s;
}
span:nth-child(17){
    animation-delay:0.32s;
}
span:nth-child(18){
    animation-delay:0.34s;
}
span:nth-child(19){
    animation-delay:0.34s;
}
span:nth-child(20){
    animation-delay:0.36s;
}
span:nth-child(22){
    animation-delay:0.38s;
}
span:nth-child(23){
    animation-delay:0.30s;
}

`
;