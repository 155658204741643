import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import click from '../../../assets/img/click.mp3';
import socialSound from '../../../assets/img/socialSound.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';

import useSound from 'use-sound';

 
const Header = () => {
    const [Home, SetHome]=useState('Home')
    const [About, SetAbout]=useState('About')
    const [skill, setSkill] = useState('Skill');
    const [Portfolio, SetPortfolio] = useState('Portfolio');
    const [Contact, SetContact] = useState('Contact');
    const [Resume, SetResume] = useState('Resume');
    const [playbackRate, setPlaybackRate] = React.useState(0.55);
    
    const [play, {stop}] = useSound(click, { playbackRate });
    const [playSocial, {stopSocial}] = useSound(socialSound);
    const [navShow, setNavShow] = useState('none')
    const [subNavShow, setSubNavShow] = useState('none')
    
    // Home
    const HomeHide = e=>{
        SetHome('Web')
        playSocial()
    } 
    const HomeShow=e=>{
        SetHome('Home')
    }
    // About
    const AboutHide = e=>{
        SetAbout('Bio')
        playSocial()
    } 
    const AboutShow=e=>{
        SetAbout('About')
    }
    
    // Skill 
    const SkillHide = e=>{  
        setSkill('Expertise')
        playSocial()
    }
    const SkillShow = e=>{
        setSkill('Skill')
    }
    
    // Portfolio
    const PortfolioHide= e =>{
        SetPortfolio('My Work')
        playSocial()
    }
    const PortfolioShow = e =>{
        SetPortfolio('Portfolio')
    }
    
    // Contact
    const ContactHide=e =>{
        SetContact('Knock Me')
        playSocial()
    }
    const ContactShow =e=>{
        SetContact('Contact')
    }
    
    // Resume
    const ResumeHide=e =>{
        SetResume('VIEW')
        playSocial()
    }
    const ResumeShow=e=>{
        SetResume('Resume')
    }
    let navStyle;
    
    
    
    const navShowHandle =()=>{
        if(navShow == 'none'){
            setNavShow('flex')
        }
        if(navShow == 'flex'){
            setNavShow('none')
        }
    } 
    let subNavClass;
    const subNavShowHandle =()=>{
        playSocial()
        if(subNavShow == 'none'){
            setSubNavShow('flex')
        }
        if(subNavShow == 'flex'){
            subNavClass = true;
            setSubNavShow('none')
        }
    } 
   
    let subNavStyle;
    subNavStyle ={
        display:subNavShow
    }
    
    navStyle = {
        display:navShow
        
    }
    return (
        <div className='Header'>
            <div className='main-Header d-flex px-5 align-items-center justify-content-between'> 
                <div className="header-logo">
                    <a href="/"><h5 className='logo-text'>MiGalib . . .</h5></a>
                    
                </div>
                    {/* <audio id='ClickSound' src={click}></audio> */}
                    <audio id='ClickSound'>
                        <source src={click} type='audio/mp3' />
                    </audio>
                <div className="main-nav-menu">

                <div className="res-nav-menu-btn text-end text-light">
                    <span onClick={()=>navShowHandle()}><FontAwesomeIcon icon={faBars} /></span>
                </div>
                <div style={navStyle} className="res-nav-menu">
                            <NavLink to='/'>
                                <span onMouseOver={HomeHide} onClick={play} onMouseOut={HomeShow} className='style-nav'>{Home}</span>
                            </NavLink>
                            <NavLink to='/About'>
                                <span onMouseOver={AboutHide} onClick={play} onMouseOut={AboutShow} className='style-nav'>{About}</span>
                            </NavLink>
                            <NavLink to='/skill'>
                                <span onMouseEnter={SkillHide} onClick={play} onMouseOut={SkillShow} className='style-nav'>{skill}</span>
                            </NavLink>
                            <NavLink to='/portfolio'>
                                <span onMouseEnter={PortfolioHide} onClick={play} onMouseOut={PortfolioShow} className='style-nav'>{Portfolio}</span>
                            </NavLink>
                            <NavLink to='/contact'>
                                <span onMouseEnter={ContactHide} onMouseOut={ContactShow} onClick={play} className='style-nav'>{Contact}</span>
                            </NavLink>
                            <NavLink to='/blog'>
                                <span onMouseEnter={()=>playSocial()}  onClick={play} className='style-nav'>Blog</span>
                            </NavLink>
                            <a onMouseEnter={ResumeHide} target="_blank" onMouseOut={ResumeShow} onClick={play} href="https://drive.google.com/file/d/1saaa3AJ3XIWPnDjiHb5a5AsRCMfwLGcS/view?usp=share_link" className='style-nav'>{Resume}</a>
                </div>
                
                <div className="header-menu">
                        <NavLink to='/'>
                            <span onMouseOver={HomeHide} onClick={play} onMouseOut={HomeShow} className='style-nav'>{Home}</span>
                        </NavLink>
                        <NavLink to='/About'>
                            <span onMouseOver={AboutHide} onClick={play} onMouseOut={AboutShow} className='style-nav'>{About}</span>
                        </NavLink>
                        <NavLink to='/skill'>
                            <span onMouseEnter={SkillHide} onClick={play} onMouseOut={SkillShow} className='style-nav'>{skill}</span>
                        </NavLink>
                        <NavLink to='/portfolio'>
                            <span onMouseEnter={PortfolioHide} onClick={play} onMouseOut={PortfolioShow} className='style-nav'>{Portfolio}</span>
                        </NavLink>
                        

                        <div>
                        <NavLink to='#'>
                            <span onMouseEnter={subNavShowHandle} onClick={()=>subNavShowHandle()} className='style-nav'>More</span>
                        </NavLink>
                            <div style={subNavStyle} onMouseLeave={subNavShowHandle} className={`header-sub-menu ${subNavClass ? 'fade-down': null}`}>
                            <NavLink to='/contact'>
                                <span onMouseEnter={ContactHide} onMouseOut={ContactShow} onClick={play} className='style-nav'>{Contact}</span>
                            </NavLink>
                            <NavLink to='/blog'>
                                <span onMouseEnter={()=>playSocial()}  onClick={play} className='style-nav'>Blog</span>
                            </NavLink>
                            <a onMouseEnter={ResumeHide} target="_blank" onMouseOut={ResumeShow} onClick={play} href="https://drive.google.com/file/d/1saaa3AJ3XIWPnDjiHb5a5AsRCMfwLGcS/view?usp=share_link" className='style-nav'>{Resume}</a>
                            </div>
                        </div>

                </div>
            </div>

            </div>
            
        </div>
    );
};

export default Header;