import React, { useEffect, useRef, useState } from 'react';
import Header from '../AllExist/Header/Header';
import Footer from '../AllExist/Footer/Footer';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import emailjs from '@emailjs/browser';
import { faX } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';




const Contact = () => {
    const [emailShow, setEmailShow] = useState('none');
    const [name, setName] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [phone, setPhone] = useState(''); 
    const [message, setMessage] = useState(''); 


    const ContactPoUpClick = e=>{
        setEmailShow('block')
    }
    const closeEmailPopup =e=> {
        setEmailShow('none')
    }

    const ContactNameField = e=>{
        setName(e.target.value);
    }
    const ContactEmailField = e=>{
        setEmail(e.target.value);
    }
    const ContactPhoneField = e=>{
        setPhone(e.target.value);
    }
    const ContactMessageField = e=>{
        setMessage(e.target.value);
    }

    const contactEmailShow = {
        display:emailShow
    }
    const clearField = ()=>{
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
    }
    
// COntact form data sed
    const form = useRef ();


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_3hyycsj', 'template_uc548qu', form.current, '4uxtNtNeL5MTIQqN-')
        .then((result) => {
            swal({
                title: "Thanks! So glad you found me",
                text: "Your message has been successfully sent",
                icon: "success",
                button: "Ok",
            });
        }, (error) => {
            swal("Please Wait!", error, "error");
        });
        clearField();
        form.current?.reset();

    };
// ----------------------------
  
    

    return (
        <div className='Contact pb-xl-0 pb-5'>
            <Header></Header>
            <div className="Contact-main d-md-flex pb-xl-0 pb-5 gap-lg-5 gap-3 pt-4 text-light">
                <div className="Contact-main-text"> 
                    <div className="Contact-main-head">
                        <h4>Contact Me for any issue</h4>
                        <p>Hey There! I'm wating for your message. Please knock me to start your project. I look forward to hearing from you.</p>
                    </div>
                    <div className='divider'></div>
                        <div className='contact-info-div'>
                        <div className="contact-inf-box contact-box mb-3">
                            <a href="tel:01731305152">
                                <div className="contact-icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                            </a>
                            <div className="icon-text">
                                <h5>Phone Number</h5>
                                <p>+8801679640589</p>
                            </div>
                        </div>
                        <div className="contact-inf-box contact-box mb-3">
                            <a href="mailto:migalibctg@gmail.com">
                                <div className="contact-icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                            </a>
                            <div className="icon-text">
                                <h5>Email</h5>
                                <div className="d-sm-flex gap-2">
                                    <p>migalibctg@gmail.com,</p>
                                    <p>migalibbd@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="contact-inf-box contact-box">
                            <a href="https://www.linkedin.com/in/minhajul-islam-galib/">
                                <div className="contact-icon">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </div>
                            </a>
                            <div className="icon-text">
                                <h5>LinkedIn Profile</h5>
                                <p>https://www.linkedin.com/in/minhajul-islam-galib/</p>
                            </div>
                        </div>
                        
                        </div>
                </div>
                <div className="Contact-main-form contact-box">
                    <form method='post' ref={form} onSubmit={sendEmail} action="">
                        <div className="name">
                            <div>
                                <label className='pt-0' htmlFor="">Your Name </label><span style={{color:'red',fontWeight: '700'}}> *</span>
                            </div>
                            <input onSelect={ContactPoUpClick} onChange={ContactNameField} type="text" name='Name' id='Name' placeholder='Ex: Mr Rahman' required />
                        </div>
                        <div className="email-div">
                            <div>
                                <label htmlFor="">Your Email </label><span style={{color:'red',fontWeight: '700'}}> *</span>
                            </div>
                            <input onSelect={ContactPoUpClick} onChange={ContactEmailField} type="email" name='Email' id='Email' placeholder='Ex: rahman@gmail.com' required />
                        </div>
                        <div className="phone-div">
                            <div>
                                <label htmlFor="">Your Phone Number </label>
                            </div>
                            <input onSelect={ContactPoUpClick} onChange={ContactPhoneField} type="tel" name='Phone-Number' id='Phone-Number' placeholder='Ex: +8801*********' />
                        </div>
                        <div className="message-div">
                            <div>
                                <label htmlFor="">Your Query or Message </label><span style={{color:'red',fontWeight: '700'}}> *</span>
                            </div>
                            <textarea onSelect={ContactPoUpClick} onChange={ContactMessageField} name="Message" id="Message" placeholder='Your Query or Message' cols="10" rows="3"></textarea>
                        </div>
                        <div>
                            <input className='work-button' type="submit" />
                        </div>
                    </form>
                </div>

                <div style={contactEmailShow} className='contact-popup'>
                    <div className='email-name-closer'>
                            <button onClick={closeEmailPopup}><FontAwesomeIcon icon={faX} /></button>
                        
                    </div>

                    <div className="email-head pb-2">
                        <div className="Name-div">
                            <h4>{name}</h4>
                        </div>

                        <div className="info-div d-flex gap-3">
                            <p>Email: {email}</p>
                            <p>Phone: {phone}</p>
                        </div>

                    </div>
                    <div className="message-body">
                            <p>Dear MiGalib</p>
                            <p className=''>{message}</p>

                    </div>

                    <div className="email-footer">
                        <p>Sincerely</p>
                        <p>{name}</p>
                    </div>
                    <div className='email-info'>
                        <p className='text-danger'>* It is better provide a WhatsApp Number I  Will contact you through your number or email. So provide a valid Email address and Phone number.</p>
                    </div>                    
                    

                </div>


            </div>
            <Footer></Footer>
        </div>
    );
};

export default Contact;