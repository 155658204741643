import axios from 'axios';
import './BlogPage.css'
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useBlog from '../../../Hooks/useBlog';
import Footer from '../../AllExist/Footer/Footer';
import Header from '../../AllExist/Header/Header';
import Headeri from '../../../assets/img/blog/blog-1.jpg';


const BlogPage = () => {
    const {id} = useParams();
    const [siteTitle, SetSiteTitle] = useState('Minhajul Islam Galib | Web developer and designer')
    const [allBlog] = useBlog([])
    const [blogs, setBlogs] = useState([]); 



//   useEffect(()=>{
//         const url = '../blog.json'

//         async function fetchData(){
//             await fetch(url)
//             .then(res=>res.json())
//             .then(data=>setMyBlogs(data))

//         }
//         fetchData()
//     },[id])
    
    
    useEffect(()=>{
        
        async function fetchDatas(){
            setBlogs(allBlog)
        }
        fetchDatas()
        SetSiteTitle(`Minhajul Islam Galib | ${blogData?.title}` )
    },[allBlog])

    const getBlog = blogs?.filter(blog=>blog.id == id)
    const blogData = getBlog[0];
    useEffect(()=>{
        document.title = siteTitle;
    },[siteTitle])

    return (
        <div>
            <Header></Header>
                <div className="Blog-Page">
                   <div className="blog-body contact-box">
                        <div className="blog-image ">
                           {/* <img src={singleData} alt="" /> */}
                        </div>
                        <div className="blog-content">

                            <h3>{blogData?.title}</h3>
                            <p>{blogData?.des}</p>
                        </div>
                   </div>
                </div>
            <Footer></Footer>
        </div>
    );
};

export default BlogPage;