import { useEffect, useState } from "react";

const UseWork = ()=>{
    const [work, setWork] = useState([]);

    useEffect(()=>{
        fetch('./work.json')
        .then(res =>res.json())
        .then(data=>setWork(data))
    },[]);
    return [work];
}
export default UseWork;