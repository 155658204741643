import React, { useEffect, useState } from 'react';
import useBlog from '../../Hooks/useBlog';
import Footer from '../AllExist/Footer/Footer';
import Header from '../AllExist/Header/Header';
import './Blog.css';
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import SingleBlog from './SingleBlog/SingleBlog';
import Loader from '../AllExist/Loader/Loader';
import { useLocation } from 'react-router-dom';


const Blog = () => {
   const [allBlog] = useBlog([])
   const [blogs, setBlogs] = useState([]); 
   
useEffect(()=>{
    setBlogs(allBlog)
},[allBlog])

    return (
        <div>
           <Header></Header>
                <div className="Blog">
                <div className='Portfolio'>
                        <div className="Portfolio-div">
                        {blogs?.length?
                        <div className="Portfolio-main">
                            <Swiper
                                className='pt-4 pt-lg-2 Blog-swiper'
                                spaceBetween={40}
                                slidesPerView={3}
                                slidesPerGroupSkip={10}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter:true
                                }}
                                initialSlide={3}
                                loop={true}
                                pagination={{
                                    clickable: false,
                                }}
                                navigation={true}
                                modules={[Autoplay, Navigation]}
                                breakpoints={{
                                    350: {
                                    // width: 576,
                                    slidesPerView: 1,
                                    },
                                    576: {
                                    // width: 576,
                                    slidesPerView: 1,
                                    },
                                    576: {
                                    // width: 768,
                                    slidesPerView: 2,
                                    },
                                    900: {
                                    // width: 768,
                                    slidesPerView: 3,
                                    },
                                }}
                            
                                >
                                    {
                                        blogs?.map(blog =>
                                        <SwiperSlide key={blog?.id}>
                                                <SingleBlog blog={blog}>
                                                    
                                                </SingleBlog>
                                        </SwiperSlide>     
                                        )
                                    }

                                </Swiper>
                        </div>
                        :       
                        <div className="loader-div">
                            <Loader></Loader>
                        </div>}

                        </div>
                    </div>
                </div>
           <Footer></Footer>
        </div>
    );
};

export default Blog;