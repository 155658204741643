import React from 'react';
import { useLocation } from 'react-router-dom';
import './FullSizeCT.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const FullSizeCT = () => {


    // function useQuery() {
        // const search = useLocation().search;
        // const getImgName = search.split("=");
        // const urlName = queryParameters.get("type")
        // const coname={query.get("name")}
        // return React.useMemo(() => new URLSearchParams(search), [search]);
        //   }
    // const imgName = query.get("name");
    // let FullCertificateImg = require('../../../assets/img/Certificate/' + imgName);

    // const ShowCTImg =() =>{
    //     document.querySelector('#full-image-ct-id').style.display='none';
    // }

    return (
            <div className='full-image-ct'>
                <div className="ct-div">
                    <img  alt="" width='100%' />
               </div>
               <button>
                   <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
    );
};

export default FullSizeCT;