import React from 'react';
import './HomeView.css'
import Header from '../AllExist/Header/Header';
import Footer from '../AllExist/Footer/Footer';
import HomeViewCode from './HomeViewCode/HomeViewCode';

const HomeView = () => {
    return (
        <div>
            <Header></Header>
                <HomeViewCode></HomeViewCode>
            <Footer></Footer>
        </div>
    );
};

export default HomeView;