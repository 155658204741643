import { Button } from 'bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './NotFound.css';
import notFound from '../../assets/img/notfound.gif'

const NotFound = () => {
    return (
        <div className='NotFound'>
            <div className="NotFound-div">
                <img src={notFound} alt="" width='35%' />
                <h5 className='mb-4'>Page Not Found</h5>
                <NavLink style={{textDecoration:'none'}} to='/' className='work-button'>Back To Home</NavLink>
                
            </div>
        </div>
    );
};

export default NotFound;