import React from 'react';
import './HomeBanner.css';
import galib from '../../../assets/img/galib.png'
import { NavLink } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
const HomeBanner = () => {
    return (
        <div className='HomeBanner'>
            <div className="HomeBanner-div d-lg-flex  justify-content-around">
                <div className="HomeBanner-image">
                {/* <LazyLoadImage
                alt="galib"
                // height={image.height}
                className='all-custom-box'
                src={galib} // use normal <img> attributes as props
                // width={image.width}
                 /> */}
                <img src={galib} className='all-custom-box' alt="" />
                </div>
                <div className="HomeBanner-content contact-box text-light">
                    <h3>Minhajul Islam Galib</h3>
                    <p className='pb-5'>Greetings!<br />
                    I'm a passionate Front-End Web Developer and Designer with 5 years of experiences. Specially, Skilled and expert in <span style={{color:'yellow'}}>WordPress platform and MERN STACK Development. </span>
                    Those who want to improve their current website or dream to make a new one or want to recruit as a team member, you are welcome to contact me by just knocking me on WhatsApp at +88 01751-523132 for a quick response. Let's work together and make something special! <br />

                    For client's convenience  the modes of services that I provide are mentioned here: <br />

                    Expertise:  HTML5 | CSS3 | JavaScript | React.js | Node.js | Bootstrap | WordPress Web development<br />

                    Comfortable:  ES6 | Node.js | Tailwind <br />

                    Familiar:  PHP | Laravel | Ajax | MySQL <br />

                    Tools:  Git | VS Code | Sublime Text | MongoDB | Netlify | Firebase</p>
                    <NavLink to={'/contact'} className='work-button'>Contact Now</NavLink>
                </div>
                
            </div>
        </div>
    );
};

export default HomeBanner;
