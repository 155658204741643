// import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
// import bgVideo from '../src/assets/img/bg-video.mp4';
// import { useRef } from 'react';
import NotFound from './Pages/NotFound/NotFound';
import Contact from './Pages/Contact/Contact';
import Portfolio from './Pages/Portfolio/Portfolio';
import Skill from './Pages/Skill/Skill';
import Certificate from './Pages/Certificate/Certificate';
import HomeView from './Pages/HomeView/HomeView';
import { useEffect, useState } from 'react';
import Loader from './Pages/AllExist/Loader/Loader';
import Blog from './Pages/Blog/Blog';
import BlogPage from './Pages/Blog/BlogPage/BlogPage';
// import ReactGA from 'react-ga';


// Google analytics
// ReactGA.initialize('UA-210558963-1');
// ReactGA.pageview(window.location.pathname + window.location.search);



function App() {

  const [loading,setLoading] = useState(true);
  if(loading){
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }
      //    for Title 
      const [siteTitle, SetSiteTitle] = useState('Minhajul Islam Galib | Web developer and designer')
      const location = useLocation().pathname;
      const pathSlice = location.split('/');
      const path = pathSlice.reverse()[0]; 
   
      useEffect(()=>{
        path?
        SetSiteTitle(`Minhajul Islam Galib | ${path}` ):
        SetSiteTitle(`Minhajul Islam Galib | Front-End Web Developer and Designer` )
    
      },[path])
      useEffect(()=>{
       document.title = siteTitle;
   },[siteTitle])
   
  return (

    <div className='App'>
        {/* <video src={bgVideo} autoPlay loop muted width="100%" id='BGvideo'></video> */}
      <div className="main-app">
        <div className="main-bg-disign"></div>
        <div className="main-bg-disign1"></div>
        <div className="main-bg-disign3"></div>
          {!loading ?
            <Routes>
              <Route exact path='/' element={<HomeView />} />
              <Route path='/home' element={<HomeView />} />
              <Route path='/about' element={<Home />} />
              <Route path='/skill' element={<Skill />} />
              <Route path='/Portfolio' element={<Portfolio />} />
              <Route path='/Contact' element={<Contact />} />
              <Route path='/Certificate' element={<Certificate />} />
              <Route path='/Blog' element={<Blog />} />
              <Route path='/Blog/:id' element={<BlogPage />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          :
          <div className="loader-div">
                    <Loader></Loader>
          </div>
          }
      </div>
    </div>
  );
}

export default App;
