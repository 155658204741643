import React, { useEffect } from 'react';
import './PortfolioSingle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompress } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { LazyLoadImage, trackWindowScroll }
  from 'react-lazy-load-image-component';
{/* <FontAwesomeIcon icon="fa-solid fa-link" /> */}

const PortfolioSingle = (props) => {
    const [Hide, setHide] = useState(false);
    const [imageStyle, setImgStyle] = useState("top");
    // const [topImage, settopImage] = useState('');
    const [imageLoader, setImageLoader] = useState(false)


    const {title, thambnail_img, tag, Live_link, Work_link, Git_link, short_des,} = props?.Work;
    const type = '.webp' || '.png'; 
    let workImg = require('../../../assets/img/portfolio/thumbnail/' + thambnail_img + type)

    // if (workImg) {
    //     setImageLoader(true)
    // }

    // useEffect(()=>{
        // if (imageLoader) {
            const topImage = {
                backgroundImage: `url(${workImg})`,
                backgroundPositionY: imageStyle
        }
    //     }
    // },[workImg])
    setInterval(() => {
        
    }, 1000);

    const PortfolioSingleHide = e=>{
        setHide(true)
        setImgStyle("bottom")
        
    }
    const PortfolioSingleShow = e=>{
        setHide(false)
        setImgStyle("top")
    }
    let ShowStyle;
    
    if(Hide){
    ShowStyle = {
        display:"block",
        top: "0",
        left: "0",
    }}else{
    ShowStyle = {
        left: "-100%",
        top: "0"
    }}

    return (
        <div className='PortfolioSingle mb-4'>
            <div className="PortfolioSingle-div text-light">
                   <div className="PortfolioSingle-img-div">
                        <div style={topImage} onMouseEnter={PortfolioSingleHide} onMouseLeave={PortfolioSingleShow} className="PortfolioSingle-img">
                            <div style={ShowStyle} className="PortfolioSingle-img-hide d-flex gap-3 justify-content-center align-items-center">
                                <a href={Live_link} target='_blank' className='work-hove-link'>
                                    <FontAwesomeIcon icon={faLink} />
                                </a>
                                <a href={Work_link} target='_blank' className='work-hove-link'>
                                    <FontAwesomeIcon icon={faCompress} />
                                </a>
                            </div>
                        </div>
                   </div>
                <div className="PortfolioSingle-content pt-3">
                    <h5 className='m-0'>{title}</h5>
                    <p className='mb-5'>{short_des}</p>
                    <div className='work-tag'>
                    {
                        tag?.map((singleTag, index) =><p key={index}>{singleTag}</p>)
                    }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioSingle;